import {
	createApp
} from 'vue'
import App from './App.vue'
import router from './router'
import store from './store'

import ElementPlus from 'element-plus'
import 'element-plus/dist/index.css'

import './assets/icon/iconfont.css'
import './base/css/reset.css'


import VueDirectiveImagePreviewer from 'vue-directive-image-previewer'
import 'vue-directive-image-previewer/dist/assets/style.css'
import http from './base/js/http.js';

import Pagination from './components/Pagination.vue'
import elHeader from "./components/header.vue"
import leftnav from "./components/leftnav.vue"





const app = createApp(App)

app.component("Pagination" , Pagination)
app.component("elHeader" , elHeader)
app.component("leftnav" , leftnav)
app.config.globalProperties.$http = http;

app.use(VueDirectiveImagePreviewer)
app.use(ElementPlus)
app.use(router)
app.use(store)
app.use(store)
app.mount('#app')