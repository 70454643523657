//测试地址
// const baseUrl = "https://zonghe-test.0715ym.cn"
// const baseUrlFile = "https://zonghe-test.0715ym.cn"

//正式地址
//
const baseUrl = "https://zonghe-api.hxcy666.com"
const baseUrlFile = ""


export {
  baseUrl,
  baseUrlFile
}


