<template>
	<div class="headerStype">
		<div class="logo">
			<span class="text">后台管理</span>
		</div>
		<div class="parentOver">
			<div class="rightParent">
				<div class="right">
					<div class="item" v-for="item in admin_menus" @click="goPageView(item )">{{item.name}}</div>
					<div class="item" @click="logout"> 退出 </div>
				</div>
			</div>
		</div>
	</div>
	<el-dialog v-model="centerDialogVisible" title="提示" width="500" center>
		<div style="height: 100px;text-align: center; line-height: 100px;font-size: 20px;"> 确定退出吗？ </div>
		<template #footer>
			<div class="dialog-footer">
				<el-button @click="centerDialogVisible = false">取消</el-button>
				<el-button type="primary" @click="logoutIn">确定 </el-button>
			</div>
		</template>
	</el-dialog>
</template>
<script setup>
	import admin_menus from "../base/js/menus.js"

	import {
		ref,
		onMounted,

		getCurrentInstance
	} from "vue";
	import {
		useRouter
	} from "vue-router";
	const router = useRouter()
	import {
		useStore
	} from 'vuex';
	const store = useStore();
	import {
		reactify
	} from '@vueuse/shared';
	import {
		ElMessage
	} from 'element-plus'
	const {
		proxy
	} = getCurrentInstance();
	const userName = ref("")
	const collapsed = ref(true)
	const user = ref({})
	const menus = ref()

	onMounted(() => {
		menus.value = [...admin_menus]
	})




	const parentOverScollType = ref(false)
	const centerDialogVisible = ref(false)

	const goPageView = (item) => {
		console.log(item.path)
		// localStorage.setItem("nowMenusIndex" , )  
		localStorage.setItem("nowChildrens", JSON.stringify(item.childrens))
		router.push({
			path: item.path
		})

	}


	// // 退出登录
	const logout = () => {
		centerDialogVisible.value = true
	}
	const logoutIn = () => {


		proxy.$http.post("/admin/admin/logout?token=" + localStorage.getItem("access_token"), {}).then((res) => {
			centerDialogVisible.value = false
			localStorage.clear()
			router.push('/')
		})
	}
</script>
<style scoped>
	.headerStype {
		width: 100%;
		height: 60px;
		border-bottom: 1px solid rgba(0, 0, 0, .3);
		text-align: right;
		box-sizing: border-box;
		background: #2c3e50;
		display: flex;
	}


	.logo {
		width: 240px;
		height: 60px;
		float: left;
		cursor: pointer;
		text-align: center;
		justify-content: center;
		align-content: center;
		display: flex;
	}


	.logo .text {
		line-height: 60px;
		font-size: 20px;
		font-weight: bold;
		color: #ffffff;
	}


	.parentOver {
		height: 60px;
		flex: 1;
		text-align: right;
	}

	.parentOverScoll {
		overflow-x: auto;
	}

	.rightParent {
		width: 100%;
		height: 60px;
		background: #2c3e50;
		display: inline-block;
	}


	.right {
		width: 500px;
		height: 60px;
		float: right;
	}


	.item {
		float: left;
		display: inline-block;
		padding: 10px 20px 0;
		cursor: pointer;
		line-height: 42px !important;
		text-align: right;
		font-size: 14px;
		color: #bbbbbb;
	}

	.item:hover .itemRouter {
		color: #3a8ee6;
	}

	.routerActive .itemRouter {
		color: #3a8ee6;
	}
</style>