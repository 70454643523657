<!--分页组件 -->
<template>
	<el-pagination class="page-box" @current-change="handleCurrentChange" background small
		:current-page="childMsg.currentPage" layout=" prev, pager, next," :total="childMsg.total">
	</el-pagination>
</template>
<script>
	export default {
		name: 'Pagination',
		props: ['childMsg'],
		data() {
			return {
				pageparm: {
					currentPage: this.childMsg.currentPage,
					pageSize: this.childMsg.pageSize
				}
			}
		},
		created() {},
		methods: {
			handleSizeChange(val) {
				this.pageparm.currentPage = 1
				this.pageparm.pageSize = val

				this.$emit('callFather', this.pageparm)
			},
			handleCurrentChange(val) {
				this.pageparm.currentPage = val
				this.$emit('callFather', this.pageparm)
			}
		}
	}
</script>

<style>
	.page-box {
		/* margin: 10px auto;
		position: fixed;
		bottom: 10px;
		left: 260px; */
	}
</style>