import axios from 'axios'
import router from '../../router';
import store from "../../store";
import {
  baseUrl
} from "./serve"

//执行方法

import {
	ElMessage
} from 'element-plus'

console.log(process.env)
//设置要访问服务器的地址
//-----------------------------------------
const base_URL = baseUrl;

//-----------------------------------------

axios.interceptors.request.use(config => {  
  if (!!localStorage.getItem("access_token")) {
    config.headers["Token"] = localStorage.getItem("access_token")
  }
   console.log(config)
  return config
}, error => {
  return Promise.reject(error)
})

axios.interceptors.response.use(response => {  
  return response
}, error => {

  return Promise.resolve(error.response)
})

function defaultHandlerResponseData(code, msg) {
  switch (code) {
    case 0:
      return true
    case 1:
      ElMessage.error(msg)
      break;
    case 401:
      ElMessage.error(msg)
      localStorage.clear()
      window.location.href = "/"
      break
    case 400:
      ElMessage.error("请求体错误")
      break
    case 500:
      ElMessage.error("服务器错误")
      break
    default:
      ElMessage.error(msg)
  }
  return false
}

function checkStatus(response) {
  // 如果http状态码正常，则直接返回数据

  console.log(response)

  // console.log(response)
  if (response && (response.status === 200)) {    
    defaultHandlerResponseData(response.data.code,response.data.message)
    return response.data
    // 如果不需要除了data之外的数据，可以直接 return response.data
  }



  // 异常状态下，把错误信息返回去
  return {
    status: 404,
    msg: '网络异常'
  }
}

function checkCode(res) {
  if (res.code == 1000) {
    console.log("登陆失效")        
	localStorage.clear()    	
  }


  // 如果code异常(这里已经包括网络错误，服务器错误，后端抛出的错误)，可以弹出一个错误提示，告诉用户
  if (res.status === -404) {
    // alert(res.msg)
  }
  if (res.data && (!res.data.success)) {
    // alert(res.data.error_msg)
  }
  return res
}

export default {
  post(url, data) {
    return axios({
      method: 'post',
      baseURL: base_URL,
      url,
      data: data, //qs.stringify(data),
      timeout: 10000,
      headers: {
        'X-Requested-With': 'XMLHttpRequest',
        'Content-Type': 'application/json; charset=UTF-8'
      }
    }).then((response) => {
      return checkStatus(response)
    }).then((res) => {
      return checkCode(res)
    })
  },
  get(url, params) {
    return axios({
      method: 'get',
      baseURL: base_URL,
      url,
      params, // get 请求时带的参数
      timeout: 10000,
      headers: {
        'X-Requested-With': 'XMLHttpRequest',
        'Content-Type': 'application/json; charset=UTF-8'
      }
    }).then((response) => {
      return checkStatus(response)
    }).then(
      (res) => {
        return checkCode(res)
      }
    )
  },
  put(url, data) {
    return axios({
      method: 'put',
      baseURL: base_URL,
      url,
      data: data, //qs.stringify(data),
      timeout: 10000,
      headers: {
        'X-Requested-With': 'XMLHttpRequest',
        'Content-Type': 'application/json; charset=UTF-8'
      }
    }).then((response) => {
      return checkStatus(response)
    }).then((res) => {
      return checkCode(res)
    })
  },

  DownloadExcel(url, params, fileName) {
    console.log(url, params, fileName)
    return axios({
      method: 'post',
      responseType: "blob",
      baseURL: base_URL,
      url,
      params, //qs.stringify(data),
      timeout: 120000,
      headers: {
        'X-Requested-With': 'XMLHttpRequest',
        'Content-Type': 'application/json; charset=UTF-8'
      }
    }).then((response) => {

      const blob = new Blob([response.data]);
      const linkNode = document.createElement('a');
      linkNode.download = fileName; //a标签的download属性规定下载文件的名称
      linkNode.style.display = 'none';
      linkNode.href = URL.createObjectURL(blob); //生成一个Blob URL
      document.body.appendChild(linkNode);
      linkNode.click(); //模拟在按钮上的一次鼠标单击
      URL.revokeObjectURL(linkNode.href); // 释放URL 对象
      document.body.removeChild(linkNode);
      return checkStatus(response)
    }).then((res) => {
      return checkCode(res)
    })
  },

}
