<template>
	<div class="headerApp">
		<elHeader></elHeader>
	</div>
	<div class="mainApp">
		<router-view />
	</div>
</template>


<style scoped>
	.headerApp {
		height: 60px;
		width: 100%;

	}

	.mainApp {
		padding: 0;
		position: fixed;
		background: #f1f1f1;
		left: 0;
		top: 60px;
		bottom: 0;
		right: 0;
		display: flex;

	}


	.menus {
		width: 240px;
		float: left;
	}

	.indexContent {
		flex: 1;
		padding: 10px;



	}
</style>