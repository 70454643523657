import {
	createRouter,
	createWebHistory,
	createWebHashHistory
} from 'vue-router'

const LoginView = () => import("../views/login.vue")
const HomeView = () => import("../views/home/index.vue")
const AbminView = () => import("../views/admin/index.vue")
const adminList = () => import("../views/admin/list.vue")
const roleList = () => import("../views/admin/role.vue")
const adminLog = () => import("../views/admin/log.vue")


const appView = () => import("../views/app/index.vue")
const appList = () => import("../views/app/list.vue")
const appDetail = () => import("../views/app/appDetail.vue")

const appData =() => import("../views/app/data.vue")
const appStat =() => import("../views/app/stat.vue")

		  

const UserView = () => import("../views/user/index.vue")
const userList = () => import("../views/user/list.vue")
const feedback = () => import("../views/user/feedback.vue")





const ProcuctView = () => import("../views/procuct/index.vue")
const ProcuctList = () => import("../views/procuct/list.vue")


const SetView = () => import("../views/set/index.vue") 
const webset = () => import("../views/set/webset.vue") 
const mobileset = () => import("../views/set/mobileset.vue") 


const routes = [
	{	path: '/',		component: LoginView,	},	
	{	path: '/home',	component: HomeView	},
	{	path: '/admin',	component: AbminView ,  redirect: '/admin/list',		
		 children: [
		      { path: 'list', name: '管理员列表', component: adminList },
		      { path: 'role', name: '角色列表', component: roleList },
		      { path: 'log', name: '管理员操作日志', component: adminLog },
		    ]
		},
	{	path: '/app',	component: appView ,  redirect: '/app/list',	
		children: [
			 { path: 'list', name: '应用列表', component: appList },	
			 { path: 'detail', name: '应用详情', component: appDetail, redirect: '/app/detail/data',	
				  children: [
					{ path: 'data', name: '数据', component: appData },	
					{ path: 'stat', name: '统计', component: appStat	 },				  
					  
			   ]
			 }			  			  
		   ]
	},
	{	path: '/user',	component: UserView,  redirect: '/user/list',	
		children: [
			 { path: 'list', name: '用户列表', component: userList },			 
			 { path: 'feedback', name: '反馈列表', component: feedback },			  			 				
		   ]	
	},
	{	path: '/procuct',	component: ProcuctView,redirect: '/procuct/list',	
		children: [
			 { path: 'list', name: '订单列表', component: ProcuctList },			 				
		   ]			
	},
	{	path: '/set',component: SetView	,redirect: '/set/webset',	
		children: [
			 { path: 'webset', name: '网站设置', component: webset },			 				
			 { path: 'mobileset', name: '协议模版', component: mobileset }	 				
		   ]	
		
	},
	
]

const router = createRouter({
	history: createWebHashHistory(),
	routes
	
})
router.beforeEach((to,from,next)=>{
	console.log(to,from)
	  if (to.path == '/') {
	    next()
	  } else {
	    const token = localStorage.getItem('access_token')
		console.log(!token)
	    if (!token) {
	      next('/login')
	    } else {
	      next()
	    }
	  }			
})
export default router