<template>
	<div class="menusBg">
		<div class="item" v-for="item in menus" @click="goPageView(item)">{{ item.name }}</div>
	</div>
</template>
<script setup>
	import {
		ref,
		reactive,
		onMounted
	} from 'vue'
	import {
		useRouter
	} from "vue-router";

	const router = useRouter()
	const menus = ref([])


	onMounted(() => {
		menus.value = JSON.parse(localStorage.getItem("nowChildrens"))
		console.log(menus.value)
	})

	const isCollapse = ref(false)

	const goPageView = (item) => {
		router.push({
			path: item.path
		})

	}

	const goHome = () => {
		console.log("000")
		router.push({
			path: "/home"
		})
	}
</script>

<style scoped>
	.item {
		width: 220px;
		height: 40px;
		line-height: 40px;
		color: #ffffff;
		padding-left: 20px;
		font-size: 14px;
		cursor: pointer;
	}
	.item:hover {
		background-color: rgb(35, 50, 64);
	}
	.menusBg {
		width: 100%;
		height: 100%;
		background: #2c3e50;
	}	
</style>