const admin_menus = [{
		name: "首页",
		path: "/home"
	},
	{
		name: "管理员",
		path: "/admin",
		childrens: [{
				name: "管理员列表",
				path: "/admin/list"
			},
			// {
			// 	name: "角色列表",
			// 	path: "/admin/role"
			// },
			{
				name: "日志列表",
				path: "/admin/log"
			},
		]
	},

	{
		name: "应用",
		path: "/app",
		childrens: [{
			name: "应用列表",
			path: "/app/list"
		}]
	},
	{
		name: "用户",
		path: "/user",
		childrens: [{
				name: "用户列表",
				path: "/user/list"
			},
			{
				name: "反馈列表",
				path: "/user/feedback"
			},
		]
	},
	{
		name: "订单",
		path: "/procuct",
		childrens: [{
			name: "订单列表",
			path: "/procuct/list"
		}]
	},
	{
		name: "配置",
		path: "/set",
		childrens: [{
				name: "网站设置",
				path: "/set/webset"
			},
			{
				name: "协议设置",
				path: "/set/mobileSet"
			},
		]
	},

]


export default admin_menus